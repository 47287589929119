// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/login/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".mine-home-page .banner{width:100%;height:1.56rem;padding:.27rem 0 0 0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:cover;background-position:50%}.mine-home-page .banner .avatar{display:block;width:.69rem;height:.69rem;margin:0 auto}.mine-home-page .banner p{margin:.14rem 0 0 0;text-align:center;font-size:.14rem;color:#fff}.mine-home-page .nav-list{padding:0 .17rem}.mine-home-page .nav-list .list-item{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;padding:.1rem 0;border-bottom:.005rem solid #eee}.mine-home-page .nav-list .list-item .item-left{font-size:.14rem;color:#f28246}.mine-home-page .nav-list .list-item span{margin-left:.06rem;font-size:.1rem;color:#2d2d2d}.mine-home-page .nav-list .list-item .item-right{margin-left:auto;font-size:.14rem;color:#999}.mine-home-page .logout-button{width:3.06rem;margin:.44rem auto 0 auto;font-size:.1rem}", ""]);
// Exports
module.exports = exports;
