<template>
    <div class="mine-home-page">
        <div class="banner">
            <img class="avatar" src="../../assets/img/icons/icon-mine-avatar.png">
            <p>当前部门：{{ app.userData.roles[0].name == "Administrator" ? "管理员" : app.userData.roles[0].name }}</p>
        </div>
        <div class="nav-list">
            <router-link class="list-item" to="order">
                <van-icon class="item-left" name="notes-o"/>
                <span>我的订单</span>
                <van-icon class="item-right" name="arrow"/>
            </router-link>
            <!--<router-link class="list-item" to="password">-->
            <!--    <van-icon class="item-left" name="lock"/>-->
            <!--    <span>修改密码</span>-->
            <!--    <van-icon class="item-right" name="arrow"/>-->
            <!--</router-link>-->
            <router-link class="list-item" to="client">
                <van-icon class="item-left" name="user-o"/>
                <span>我的客户</span>
                <van-icon class="item-right" name="arrow"/>
            </router-link>
        </div>
        <van-button block class="logout-button" color="#F54A4A" type="primary" @click="logout()">退&nbsp;&nbsp;出
        </van-button>
    </div>
</template>

<script>
// 引入mixins
import mixins_config from "@/mixins/config.js";
// 引入组件
import GeekQooSearch from "@/components/GeekQooSearch"
// 引入接口
import {LOGOUT} from "@/api/dataProvider";
// 引入本地存储操作
import {delCookie} from "@/utils/storage";

export default {
    name: "MineHome",
    mixins: [mixins_config],
    components: {GeekQooSearch},
    data() {
        return {};
    },
    created() {
    },
    mounted() {
    },
    methods: {
        logout() {
            let that = this
            LOGOUT({}, function (res) {
                if (res.data.code == 0) {
                    delCookie("token")
                    that.$notify(
                        {
                            type: "success",
                            message: "退出成功，即将跳转登录页！",
                            onClose() {
                                that.$router.push({name: "Login"})
                            }
                        }
                    );
                }
            })
        }
    }
}
</script>

<style lang="scss">
.mine-home-page {
    .banner {
        width: 100%;
        height: 156px;
        padding: 27px 0 0 0;
        background: url("../../assets/img/login/bg.png") no-repeat;
        background-size: cover;
        background-position: center;

        .avatar {
            display: block;
            width: 69px;
            height: 69px;
            margin: 0 auto;
        }

        p {
            margin: 14px 0 0 0;
            text-align: center;
            font-size: 14px;
            color: #fff;
        }
    }

    .nav-list {
        padding: 0 17px;

        .list-item {
            display: flex;
            align-items: center;
            padding: 10px 0;
            border-bottom: 0.5px solid #EEEEEE;

            .item-left {
                font-size: 14px;
                color: #F28246;
            }

            span {
                margin-left: 6px;
                font-size: 10px;
                color: #2D2D2D;
            }

            .item-right {
                margin-left: auto;
                font-size: 14px;
                color: #999999;
            }
        }
    }

    .logout-button {
        width: 306px;
        margin: 44px auto 0 auto;
        font-size: 10px;
    }
}
</style>